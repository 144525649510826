import React from "react";
import { loginRequired } from "../../../routes/Authorisation";
import AppRoute from "../../../routes/definitions/route";
import HomeEndpoint from "./HomeEndpoint";

const HomePageLazy = React.lazy(() => import("./HomePage"));

const HomeRoute: AppRoute = {
  path: HomeEndpoint,

  isAuthorised: loginRequired,

  name: "HomePage",
  description: "Hera Homepage",

  element: <HomePageLazy />,
};

export default HomeRoute;
