import AppRoute from "../../../routes/definitions/route";
import PageNotFound from "./PageNotFound";

const PageNotFoundRoute: AppRoute = {
  name: "Page Not Found",
  description: "The page you where looking for could not be found!",

  element: <PageNotFound />,
};

export default PageNotFoundRoute;
