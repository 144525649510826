import { Moment } from "moment";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { RootState } from "../../features/store";

export interface ColumnFilters {
  [id: string]: InternalColumnFilter;
}

export enum FilterType {
  Text = "text",
  Number = "number",
  Select = "select",
  Range = "range",
  Date = "date",
}

export type ColumnFilter = {
  name: string;
  type: FilterType;
  column: string;
  params: string[];
  frontend_only?: boolean;
};

export interface InternalColumnFilter {
  [id: string]: string[] | string | number | Moment;
}

export interface SearchObject {
  [id: string]: string;
}

interface GeneralState {
  columnFilters: ColumnFilters;
  searchObject: SearchObject;
}

const initialState: GeneralState = {
  columnFilters: {},
  searchObject: {},
};

export const filterGridSlice = createSlice({
  name: "filterGrid",
  initialState,
  reducers: {
    setSearchText: (state, action: PayloadAction<SearchObject>) => {
      state.searchObject = action.payload;
    },
    setColumnFilters: (state, action: PayloadAction<ColumnFilters>) => {
      state.columnFilters = action.payload;
    },
  },
});

export const { setColumnFilters, setSearchText } = filterGridSlice.actions;

export const selectColumnFilterValues = (state: RootState) =>
  state.filterGrid.columnFilters;

export const selectSearchObject = (state: RootState) =>
  state.filterGrid.searchObject;

const persistConfig = {
  key: filterGridSlice.name,
  storage,
  whitelist: ["columnFilters"],
};

export default persistReducer(persistConfig, filterGridSlice.reducer);
