import { Components } from "@mui/material";
import breakpoints from "../breakpoints";

export default {
  styleOverrides: {
    root: {
      fontSize: "13px",

      [breakpoints.up("sm")]: {
        minHeight: "auto",
      },
    },
  },
} as Components["MuiMenuItem"];
