import { PaletteOptions } from "@mui/material/styles/createPalette";

export default {
  mode: "dark",
  background: {
    default: "#1B2635",
    paper: "#233044",
  },
  primary: {
    main: "#417AD5",
  },
  secondary: {
    main: "#00E5FF",
  },
  error: {
    main: "#D32F2F",
  },
} as PaletteOptions;
