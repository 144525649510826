import { useMediaQuery } from "@mui/material";
import { selectDarkMode } from "../features/general/generalSlice";
import { useAppSelector } from "../features/hooks";

export default function useDarkMode() {
  const darkMode = useAppSelector(selectDarkMode);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  return darkMode === "dark" || (darkMode === "detect" && prefersDarkMode);
}
