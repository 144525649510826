import React from "react";
import { loginRequired } from "../../routes/Authorisation";
import AppRoute from "../../routes/definitions/route";
import LOGGED_IN_ROUTE_CHILDREN from "./LoggedInRouteChildren";

const LoggedInLazy = React.lazy(() => import("./LoggedIn"));

const LoggedInRoute: AppRoute = {
  path: "/*",

  isAuthorised: loginRequired,

  name: "HomePage",
  description: "Hera Homepage",

  element: <LoggedInLazy />,

  rendersOwnChildren: true,
  children: LOGGED_IN_ROUTE_CHILDREN,
};

export default LoggedInRoute;
