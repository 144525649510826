import history from "@kwaleeltd/common/lib/util/history";
import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { SWRConfig } from "swr";
import App from "./app/App";
import Notifier from "./app/Notifier";
import ServiceWorker from "./app/ServiceWorker";
import "./data";
import ReduxProvider from "./features/store";
import "./styles/perfect-scrollbar.css";
import Theme from "./theme/Theme";

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider>
      <Router history={history}>
        <HelmetProvider>
          <SWRConfig value={{}}>
            <Theme>
              <Notifier />

              <App />

              <ServiceWorker />
            </Theme>
          </SWRConfig>
        </HelmetProvider>
      </Router>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
