import { Components } from "@mui/material";

export default {
  styleOverrides: {
    root: {
      marginTop: 18,
      marginBottom: 18,
    },
  },
} as Components["MuiDivider"];
