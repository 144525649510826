import Endpoint from "../../../data/Endpoint";

export const STEAM: Endpoint = "/steam";
/** Gets all steam apps */
export const STEAM_APPS: Endpoint = `${STEAM}/apps`;
/** Gets all steam genres */
export const STEAM_GENRES: Endpoint = `${STEAM}/genres`;
/** Gets all steam categories */
export const STEAM_CATEGORIES: Endpoint = `${STEAM}/categories`;
/** Gets all steam publishers */
export const STEAM_PUBLISHERS: Endpoint = `${STEAM}/publishers`;
/** Gets all steam developers */
export const STEAM_DEVELOPERS: Endpoint = `${STEAM}/developers`;
/** Blacklist a developer */
export const STEAM_BLACKLIST_DEVELOPER: Endpoint = `${STEAM}/developer/{instance_id}`;
/** Gets all blacklisted developers */
export const STEAM_BLACKLISTED_DEVELOPERS: Endpoint = `${STEAM}/developers`;
/** Blacklist a publisher */
export const STEAM_BLACKLIST_PUBLISHER: Endpoint = `${STEAM}/publisher/{instance_id}`;
/** Gets all blacklisted publishers */
export const STEAM_BLACKLISTED_PUBLISHERS: Endpoint = `${STEAM}/publishers`;
/** Gets all blacklisted publishers */
export const STEAM_BLACKLISTED_APPS: Endpoint = `${STEAM}/apps`;
/** Gets all employee holiday details */
export const STEAM_EMPLOYEE_DETAILS: Endpoint = `/employees/holiday-information`;
