import WarningIcon from "@mui/icons-material/Warning";
import React, { PropsWithChildren } from "react";
import ErrorPage from "../ErrorPage";

type ErrorBoundaryProps = PropsWithChildren<{
  requirePageReload?: boolean;
}>;

type ErrorBoundaryState = {
  error?: Error;
};

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {};

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  render() {
    if (!this.state.error) {
      return this.props.children;
    }

    return (
      <ErrorPage
        icon={WarningIcon}
        heading="Oops..."
        subText="Something went wrong!"
        error={this.state.error}
        to={
          this.props.requirePageReload
            ? () => window.location.reload()
            : undefined
        }
        clearErrorState={() => this.setState({ error: undefined })}
        linkText={this.props.requirePageReload ? "Reload page" : undefined}
      />
    );
  }
}
