import { Components } from "@mui/material";

export default {
  styleOverrides: {
    root: {
      borderRadius: "6px",
      padding: 0,
    },
    label: {
      paddingLeft: "12px",
      paddingRight: "12px",
    },
  },
} as Components["MuiChip"];
