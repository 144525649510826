import { AxiosRequestConfig } from "axios";
import { isValidURL } from "../axiosConfig";

export function appendTimestamp(config: AxiosRequestConfig) {
  if (isValidURL(config)) {
    if (config.headers?.timestamp === undefined) {
      if (config.headers === undefined) {
        config.headers = {};
      }

      config.headers.timestamp = Date.now().toString();
    }

    let isParams = false;
    switch (config.method?.toLowerCase()) {
      case "get":
      case "delete":
        isParams = true;
        break;
      default:
        break;
    }

    let data = isParams ? config.params : config.data;
    if (!data) {
      data = {};
    }

    if (data.timestamp === undefined) {
      data = {
        ...data,
        timestamp: Date.now(),
      };

      if (isParams) {
        config.params = data;
      } else {
        config.data = data;
      }
    }
  }

  return config;
}
