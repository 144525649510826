import { Box, Button, Input, SvgIcon, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { To } from "history";
import React, { MouseEvent, useMemo } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../features/hooks";
import { enqueueNotification } from "../../features/notification/notificationSlice";

type ErrorPageProps = {
  icon: typeof SvgIcon;
  heading: string;
  subText: string;
  error?: Error;
  to?: To | (() => void);
  clearErrorState?: () => void;
  linkText?: string;
};

export default function ErrorPage({
  icon,
  heading,
  subText,
  error,
  to = "/",
  clearErrorState,
  linkText = "Back to Home Page",
}: ErrorPageProps) {
  const dispatch = useAppDispatch();

  const IconComponent = icon;

  const isRedirect = typeof to !== "function";
  const button = (
    <Button
      variant="text"
      sx={{ marginTop: 2 }}
      onClick={
        isRedirect
          ? undefined
          : () => {
              clearErrorState?.();
              to();
            }
      }
    >
      {linkText}
    </Button>
  );

  const errorEncoded = useMemo(
    () => (error?.stack ? btoa(error.stack) : undefined),
    [error]
  );

  const onErrorClick = async (event: MouseEvent) => {
    const target = event.target as HTMLInputElement;
    target.select();

    let success = false;
    try {
      await navigator.clipboard.writeText(target.value);

      success = true;
    } catch (e) {
      console.error(e);
    }

    if (!success) {
      try {
        document.execCommand("copy");

        success = true;
      } catch (e) {
        console.error(e);
      }
    }

    if (success) {
      dispatch(
        enqueueNotification({
          message:
            "Successfully copied error to the clipboard, please pass this on to the server team... ❤️",
          options: {
            key: "success_copy",
            variant: "success",
          },
        })
      );
    } else {
      dispatch(
        enqueueNotification({
          message:
            "Failed to copy error to clipboard, please do so manually...",
          options: {
            key: "error_copy",
            variant: "error",
          },
        })
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        width: "100%",
        height: "100%",
      }}
    >
      <IconComponent fontSize="large" />
      <Typography variant="h1" mt={2}>
        {heading}
      </Typography>
      <Typography variant="h4" component="h2" color={blueGrey[300]} mt={1}>
        {subText}
      </Typography>
      {!!errorEncoded && (
        <Box sx={{ paddingTop: 2 }}>
          <Typography>
            Please provide the error below to a member of the server team:
          </Typography>
          <Input
            sx={{ paddingTop: 1 }}
            fullWidth
            type="text"
            value={errorEncoded}
            onClick={onErrorClick}
          />
        </Box>
      )}

      {isRedirect ? (
        <Link to={to} onClick={clearErrorState}>
          {button}
        </Link>
      ) : (
        button
      )}
    </Box>
  );
}
