import { AxiosRequestConfig } from "axios";
import { store } from "../../features/store";
import { axiosConfig, isValidURL } from "../axiosConfig";
import { appendTimestamp } from "./timestampInterceptor";

export function appendAuthHeader(config: AxiosRequestConfig) {
  appendTimestamp(config);

  if (isValidURL(config)) {
    const email = store.getState().session.email;
    const token = store.getState().session.token;

    // Get timestamp from request
    let timestamp = Date.now();
    let isParams = false;
    switch (config.method?.toLowerCase()) {
      case "get":
      case "delete":
        isParams = true;
        break;
      default:
        break;
    }

    const data = isParams ? config.params : config.data;
    if (data?.timestamp !== undefined) {
      timestamp = data.timestamp;
    }

    if (email && token) {
      if (!config.headers) {
        config.headers = {};
      }

      config.headers["Authorization"] = `Bearer ${btoa(
        `${email}:${token}:${timestamp}`
      )}`;
    }
  }

  return config;
}

axiosConfig.interceptors.request.use(appendAuthHeader, (err) => {
  throw err;
});
