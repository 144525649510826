import { Button } from "@mui/material";
import { SnackbarKey, useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import * as serviceWorker from "../serviceWorker";

// Refreshes other tabs when a user updates the service worker
let refreshing = false;
navigator.serviceWorker?.addEventListener("controllerchange", function () {
  if (refreshing) {
    return;
  }

  refreshing = true;

  window.location.reload();
});

export default function ServiceWorker() {
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const toastRef = useRef<SnackbarKey>();
  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setWaitingWorker(registration.waiting);

    toastRef.current = enqueueSnackbar("A new update is available!", {
      key: "service_worker_update_available",
      persist: true,
      preventDuplicate: true,
      variant: "info",
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      action: (
        <Button color="inherit" size="small" onClick={reloadPage}>
          Update
        </Button>
      ),
      onClose: () => (toastRef.current = undefined),
    });
  };

  const reloadPage = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });

    if (toastRef.current) {
      closeSnackbar(toastRef.current);
    }

    window.location.reload();
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  return null;
}
