import { PermissionsObject } from "../permissions/Permission";
import AccessType from "../permissions/AccessType";
import { Permission } from "../permissions/Permission";

export type HasPermissionFn = (
  id: Permission,
  accessType: AccessType
) => boolean;

export type AuthorisationData = {
  access?: PermissionsObject;
  permissions: PermissionData;
};

export type PermissionData = {
  hasPermission: HasPermissionFn;
};

export const loginRequired = ({ access }: AuthorisationData) => !!access;
export const noLoginRequired = ({ access }: AuthorisationData) => !access;

export const permissionRequired =
  (permission: Permission, accessType: AccessType) =>
  ({ permissions }: AuthorisationData) =>
    permissions.hasPermission(permission, accessType);
