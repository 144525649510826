import { ThemeOptions } from "@mui/material";
import breakpoints from "./breakpoints";
import components from "./components";
import palette from "./palette";
import typography from "./typography";

export default {
  palette,
  typography,
  breakpoints,
  components,
} as ThemeOptions;
