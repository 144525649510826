import {
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { Location, Path } from "history";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { push } from "redux-first-history";
import Logo from "../../common/Logo";
import { useAppDispatch, useAppSelector } from "../../features/hooks";
import { login, selectSession } from "../../features/session/sessionSlice";
import { RedirectLocation } from "../../routes/ProtectedRoute";
import buildTimestamp from "../../util/buildTimestamp";
import DarkModeButton from "../LoggedIn/AppBar/Button/DarkModeButton";

const inputStyle = {
  marginTop: 1,
  marginBottom: 1,
} as const;

export default function AuthenticatePage() {
  const location = useLocation() as Location & { state: RedirectLocation };

  const theme = useTheme();

  const session = useAppSelector(selectSession);
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Redirect logged in users
  const isLoggedIn = !!session.token;
  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const target: Partial<Path> = {
      pathname: "/",
    };

    // Redirect them to where they got denied
    if (!!location.state?.pathname && location.state.pathname !== "/login") {
      target.pathname = location.state.pathname;
      target.search = location.state.search;
      target.hash = location.state.hash;
    }

    dispatch(push(target));
  }, [isLoggedIn]);

  const errorMap = new Map<string, string | undefined>();
  if (email.trim().length < 1) {
    errorMap.set("email", undefined);
  }

  if (password.trim().length < 1) {
    errorMap.set("password", undefined);
  }

  return (
    <Box
      sx={{
        maxWidth: 520,
        minHeight: "100%",
        margin: "0 auto",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 1,
          right: 1,
        }}
      >
        <DarkModeButton />
      </Box>

      <Paper
        sx={{
          padding: {
            xs: 3,
            md: 5,
          },
        }}
      >
        <Box
          sx={{
            width: 92,
            height: 92,
            textAlign: "center",
            margin: "0 auto",

            "& img": {
              width: "100%",
              height: "100%",
              objectFit: "cover",
              textAlign: "center",
            },
          }}
        >
          <Logo sx={{ width: "100%", height: "100%" }} />
        </Box>

        <Typography variant="h4" align="center" fontWeight="bold">
          Hera
        </Typography>
        <Typography variant="body1" align="center">
          Sign in to your account to continue
        </Typography>

        <Box
          component="form"
          sx={{
            marginTop: 1,
          }}
        >
          <TextField
            fullWidth
            sx={inputStyle}
            id="email"
            label="Email"
            variant="outlined"
            error={errorMap.has("email")}
            helperText={errorMap.get("email")}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />

          <TextField
            fullWidth
            sx={inputStyle}
            id="password"
            type="password"
            label="Password"
            variant="outlined"
            error={errorMap.has("password")}
            helperText={errorMap.get("password")}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />

          <Button
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            sx={inputStyle}
            type="submit"
            disabled={session.isLoading}
            onClick={(event) => {
              event.preventDefault();

              dispatch(login(email, password));
            }}
          >
            {session.isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
            Sign in
          </Button>

          <Box
            sx={{
              marginTop: theme.spacing(),
            }}
          >
            <Typography component="p" variant="caption" align="center">
              v{process.env.REACT_APP_VERSION}
            </Typography>
            <Typography
              component="p"
              variant="caption"
              align="center"
              color="textSecondary"
            >
              {buildTimestamp}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
