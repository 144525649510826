import { Box } from "@mui/material";
import React from "react";
import LoadingSuspense from "../common/LoadingSuspense";
import ROUTES_PROTECTED from "../routes/definitions";
import RouteRenderer from "../routes/RouteRenderer";
import ErrorBoundary from "./Error/ErrorBoundary/ErrorBoundary";

function App() {
  return (
    <Box height={1}>
      <ErrorBoundary requirePageReload>
        <LoadingSuspense subText="Page Content">
          <RouteRenderer routes={ROUTES_PROTECTED} />
        </LoadingSuspense>
      </ErrorBoundary>
    </Box>
  );
}

export default App;
