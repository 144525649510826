import { AxiosError } from "axios";
import { enqueueNotification } from "../../features/notification/notificationSlice";
import { store } from "../../features/store";
import { axiosConfig, isValidURL } from "../axiosConfig";

function interceptServerSideError(err: AxiosError<any>) {
  if (isValidURL(err.config)) {
    if (typeof err.response === "undefined") {
      store.dispatch(
        enqueueNotification({
          message:
            "A network error has occurred, this could be a server side issue or a lost internet connection...",
          options: {
            key: "error_axios_undefined",
            variant: "error",
          },
        })
      );
    } else if (err.response?.status === 500) {
      store.dispatch(
        enqueueNotification({
          message:
            "An unknown server error has occurred, please contact a member of the server team...",
          options: {
            key: "error_axios_500",
            variant: "error",
          },
        })
      );
    } /* else if (is404(err)) {
      const message = err.response?.data.error_message;
      if (message) {
        store.dispatch(
          enqueueNotification({
            message,
            options: {
              key: "error_axios_404",
              variant: "error",
            },
          })
        );
      }
    }*/
  }

  throw err;
}

axiosConfig.interceptors.response.use(
  (response) => response,
  interceptServerSideError
);
