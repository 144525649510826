import React from "react";
import { loginRequired } from "../../../routes/Authorisation";
import AppRoute from "../../../routes/definitions/route";
import {
  SteamAppEndpoint,
  SteamAppsEndpoint,
  SteamCategoriesEndpoint,
  SteamCategoryEndpoint,
  SteamDeveloperEndpoint,
  SteamDevelopersEndpoint,
  SteamGenreEndpoint,
  SteamGenresEndpoint,
  SteamPublisherEndpoint,
  SteamPublishersEndpoint,
} from "./SteamEndpoint";

const SteamAppsPageLazy = React.lazy(() => import("./SteamApp/SteamAppsPage"));

export const SteamAppsRoute: AppRoute = {
  path: SteamAppsEndpoint,

  isAuthorised: loginRequired,

  name: "Steam Apps",
  description: "View All Steam Apps",

  element: <SteamAppsPageLazy />,
};

const SteamAppPageLazy = React.lazy(() => import("./SteamApp/SteamAppPage"));

export const SteamAppRoute: AppRoute = {
  path: SteamAppEndpoint,

  isAuthorised: loginRequired,

  name: "Steam App",
  description: "View Steam App Information",

  element: <SteamAppPageLazy />,
};

const SteamGenresPageLazy = React.lazy(
  () => import("./SteamGenre/SteamGenresPage")
);

export const SteamGenresRoute: AppRoute = {
  path: SteamGenresEndpoint,

  isAuthorised: loginRequired,

  name: "Steam Genres",
  description: "View All Steam Genres",

  element: <SteamGenresPageLazy />,
};

const SteamGenrePageLazy = React.lazy(
  () => import("./SteamGenre/SteamGenrePage")
);

export const SteamGenreRoute: AppRoute = {
  path: SteamGenreEndpoint,

  isAuthorised: loginRequired,

  name: "Steam Genre",
  description: "View Steam Genre Information",

  element: <SteamGenrePageLazy />,
};

const SteamPublishersPageLazy = React.lazy(
  () => import("./SteamPublisher/SteamPublishersPage")
);

export const SteamPublishersRoute: AppRoute = {
  path: SteamPublishersEndpoint,

  isAuthorised: loginRequired,

  name: "Steam Publishers",
  description: "View All Steam Publishers",

  element: <SteamPublishersPageLazy />,
};

const SteamPublisherPageLazy = React.lazy(
  () => import("./SteamPublisher/SteamPublisherPage")
);

export const SteamPublisherRoute: AppRoute = {
  path: SteamPublisherEndpoint,

  isAuthorised: loginRequired,

  name: "Steam Publisher",
  description: "View Steam Publisher Information",

  element: <SteamPublisherPageLazy />,
};

const SteamDevelopersPageLazy = React.lazy(
  () => import("./SteamDeveloper/SteamDevelopersPage")
);

export const SteamDevelopersRoute: AppRoute = {
  path: SteamDevelopersEndpoint,

  isAuthorised: loginRequired,

  name: "Steam Developers",
  description: "View All Steam Developers",

  element: <SteamDevelopersPageLazy />,
};

const SteamDeveloperPageLazy = React.lazy(
  () => import("./SteamDeveloper/SteamDeveloperPage")
);

export const SteamDeveloperRoute: AppRoute = {
  path: SteamDeveloperEndpoint,

  isAuthorised: loginRequired,

  name: "Steam Developer",
  description: "View Steam Developer Information",

  element: <SteamDeveloperPageLazy />,
};

const SteamCategoriesPageLazy = React.lazy(
  () => import("./SteamCategory/SteamCategoriesPage")
);

export const SteamCategoriesRoute: AppRoute = {
  path: SteamCategoriesEndpoint,

  isAuthorised: loginRequired,

  name: "Steam Categories",
  description: "View All Steam Categories",

  element: <SteamCategoriesPageLazy />,
};

const SteamCategoryPageLazy = React.lazy(
  () => import("./SteamCategory/SteamCategoryPage")
);

export const SteamCategoryRoute: AppRoute = {
  path: SteamCategoryEndpoint,

  isAuthorised: loginRequired,

  name: "Steam Category",
  description: "View Steam Category Information",

  element: <SteamCategoryPageLazy />,
};

export const SteamRoutes = [
  SteamAppsRoute,
  SteamAppRoute,
  SteamGenresRoute,
  SteamGenreRoute,
  SteamPublishersRoute,
  SteamPublisherRoute,
  SteamDevelopersRoute,
  SteamDeveloperRoute,
  SteamCategoriesRoute,
  SteamCategoryRoute,
];
