import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AccessType from "../../permissions/AccessType";
import { enqueueNotification } from "../notification/notificationSlice";
import {
  LoginResponse,
  Permission,
  PermissionsObject,
} from "../../permissions/Permission";
import { AppThunk, RootState } from "../store";
import {
  login as loginRequest,
  logout as logoutRequest,
} from "./sessionService";

export interface SessionState {
  isLoading: boolean;
  email: string | undefined;
  token: string | undefined;
  timestamp: number | undefined;
  access: PermissionsObject | undefined;
}

const initialState: SessionState = {
  isLoading: false,
  email: undefined,
  token: undefined,
  timestamp: undefined,
  access: undefined,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setToken: (
      state,
      action: PayloadAction<{
        email: string | undefined;
        token: string | undefined;
        timestamp: number | undefined;
        access: PermissionsObject | undefined;
      }>
    ) => {
      state.email = action.payload.email;
      state.token = action.payload.token;
      state.timestamp = action.payload.timestamp;
      state.access = action.payload.access;
    },
    setTokenTimeout: (state, action: PayloadAction<number | undefined>) => {
      state.timestamp = action.payload;
    },
  },
});

const { setLoading, setToken } = sessionSlice.actions;
export const { setTokenTimeout } = sessionSlice.actions;

export const selectTimestamp = (store: RootState) => store.session.timestamp;

export const login =
  (email: string, password: string): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));

    let response:
      | (LoginResponse & {
          timestamp: number;
        })
      | undefined;
    try {
      response = await loginRequest(email, password);
    } catch (e) {
      console.error(e);

      dispatch(
        enqueueNotification({
          message: "Invalid username/password...",
          options: {
            key: "login_fail",
            variant: "error",
          },
        })
      );
    }

    if (response) {
      const { session_token, timestamp, access } = response;

      dispatch(setToken({ email, token: session_token, timestamp, access }));

      dispatch(
        enqueueNotification({
          message: "Successfully logged in!",
          options: {
            key: "login_success",
            variant: "success",
          },
        })
      );
    }

    dispatch(setLoading(false));
  };

export const logout =
  (notify: boolean = true): AppThunk =>
  async (dispatch, getState) => {
    let promise: Promise<any> = Promise.resolve();
    if (getState().session.token) {
      // Only make web request if a session is there to log out
      promise = logoutRequest();
    }

    try {
      await promise;
    } catch (e) {
      console.error(e);
    }

    dispatch(
      setToken({
        email: undefined,
        token: undefined,
        timestamp: undefined,
        access: undefined,
      })
    );

    if (notify) {
      dispatch(
        enqueueNotification({
          message: "Successfully logged out!",
          options: {
            key: "logout_success",
            variant: "success",
          },
        })
      );
    }
  };

export const selectSession = (state: RootState) => state.session;

export const selectHasPermission =
  (state: RootState) => (id: Permission, accessType: AccessType) => {
    const access = state.session.access;

    const clsName = access?.cls_names ?? {};

    if (access?.superuser) {
      return true;
    }

    const permission = clsName[id];

    if (!permission) {
      return false;
    }

    switch (accessType) {
      case AccessType.READ:
        return permission.read;
      case AccessType.UPDATE:
        return permission.update;
      case AccessType.CREATE:
        return permission.create;
      case AccessType.DELETE:
        return permission.delete;
      default:
        break;
    }

    return false;
  };

const persistConfig = {
  key: sessionSlice.name,
  storage,
  blacklist: ["isLoading"],
};

export default persistReducer(persistConfig, sessionSlice.reducer);
