import { Components } from "@mui/material";

export default {
  styleOverrides: {
    html: {
      height: "100%",
    },
    body: {
      height: "100%",
    },
  },
} as Components["MuiCssBaseline"];
