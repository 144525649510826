import { Components } from "@mui/material";
import MuiButton from "./MuiButton";
import MuiChip from "./MuiChip";
import MuiCssBaseline from "./MuiCssBaseline";
import MuiDivider from "./MuiDivider";
import MuiLink from "./MuiLink";
import MuiMenuItem from "./MuiMenuItem";

export default {
  MuiButton,
  MuiChip,
  MuiCssBaseline,
  MuiDivider,
  MuiLink,
  MuiMenuItem,
} as Partial<Components>;
