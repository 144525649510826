import { BreakpointsOptions } from "@mui/material";
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
} as BreakpointsOptions;

export default createBreakpoints({ ...breakpoints });
