import React from "react";
import { loginRequired } from "../../../routes/Authorisation";
import AppRoute from "../../../routes/definitions/route";
import KwalidaysEndpoint from "./KwalidaysEndpoint";

const KwalidaysLazy = React.lazy(() => import("./Kwalidays"));

const KwalidaysRoute: AppRoute = {
  path: KwalidaysEndpoint,

  isAuthorised: loginRequired,

  name: "HomePage",
  description: "Hera Homepage",

  element: <KwalidaysLazy />,
};

export default KwalidaysRoute;
