import { MetaProps } from "@kwaleeltd/common/lib/Meta/Meta";
import isNullOrUndefined from "@kwaleeltd/common/lib/util/isNullOrUndefined";
import { Hash, Pathname, Search } from "history";
import { FunctionComponent, ReactNode } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useAppSelector } from "../features/hooks";
import {
  selectHasPermission,
  selectSession,
} from "../features/session/sessionSlice";
import { AuthorisationData } from "./Authorisation";
import ComponentRoute from "./ComponentRoute";

export const protectElement = (
  element: ProtectedRouteProps["element"],
  props: Omit<ProtectedRouteProps, "element">
) => <ProtectedRoute element={element} name="" {...props} />;

export const homeRedirect = ({ from }: { from?: string } = {}) => (
  <Route path={from ?? "*"} element={<Navigate to="/" replace />} />
);

export interface RedirectLocation {
  pathname: Pathname;
  search: Search;
  hash: Hash;
}

type ProtectedRouteProps = {
  element: ReactNode;
  isAuthorised?: boolean | ((data: AuthorisationData) => boolean);
} & MetaProps;

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({
  isAuthorised,
  ...rest
}) => {
  const session = useAppSelector(selectSession);
  const hasPermission = useAppSelector(selectHasPermission);

  const location = useLocation();

  let isAuthorisedResult: boolean = true;
  if (!isNullOrUndefined(isAuthorised)) {
    if (typeof isAuthorised === "boolean") {
      isAuthorisedResult = isAuthorised;
    } else {
      isAuthorisedResult = isAuthorised({
        access: session?.access,
        permissions: {
          hasPermission,
        },
      });
    }
  }

  if (!isAuthorisedResult) {
    return (
      <Routes>
        {!!session?.token ? (
          homeRedirect()
        ) : (
          <Route
            path="*"
            element={
              <Navigate
                to={{
                  pathname: "/login",
                }}
                state={{
                  pathname: location.pathname,
                  search: location.search,
                  hash: location.hash,
                }}
              />
            }
          />
        )}
      </Routes>
    );
  }

  return <ComponentRoute {...rest} />;
};

export default ProtectedRoute;
