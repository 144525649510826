import { AxiosError } from "axios";
import { enqueueNotification } from "../../features/notification/notificationSlice";
import { AUTHENTICATE } from "../../features/session/sessionEndpoint";
import { logout } from "../../features/session/sessionSlice";
import { store } from "../../features/store";
import { axiosConfig, isValidURL } from "../axiosConfig";

function interceptUnauthorised(err: AxiosError) {
  if (isValidURL(err.config) && err.response?.status === 401) {
    const session = store.getState().session;
    if (session.token) {
      if (err.config.url !== "/logout") {
        store.dispatch(logout(false));
      }

      if (Date.now() - session.timestamp! > 60 * 60 * 1000) {
        store.dispatch(
          enqueueNotification({
            message: "You have been logged out due to inactivity!",
            options: {
              key: "logout_inactive",
              variant: "error",
              autoHideDuration: 10000,
            },
          })
        );
      } else {
        store.dispatch(
          enqueueNotification({
            message: "You do not have permission to perform that action!",
            options: {
              key: "logout_permission",
              variant: "error",
            },
          })
        );
      }
    } else if (err.config.url?.endsWith(AUTHENTICATE)) {
      store.dispatch(
        enqueueNotification({
          message: "Invalid email/password provided!",
          options: {
            key: "login_fail",
            variant: "error",
          },
        })
      );
    } else {
      store.dispatch(
        enqueueNotification({
          message: "You must be logged in to access this!",
          options: {
            key: "login_required",
            variant: "error",
          },
        })
      );
    }
  }

  throw err;
}

axiosConfig.interceptors.response.use(
  (response) => response,
  interceptUnauthorised
);
