import AppRoute from "../../routes/definitions/route";
import AuthenticatePage from "./AuthenticatePage";
import AuthenticateEndpoint from "./AuthenticateEndpoint";

const AuthenticateRoute: AppRoute = {
  path: AuthenticateEndpoint,

  name: "Login",
  description: "Login to Buckbeak",

  element: <AuthenticatePage />,
};

export default AuthenticateRoute;
