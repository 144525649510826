import { Components } from "@mui/material";

export default {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      textTransform: "none",
    },
  },
} as Components["MuiButton"];
