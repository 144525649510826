import { protectElement } from "../ProtectedRoute";
import ENTRY_ROUTE from "./EntryRoute";
import AppRoute from "./route";

export function generateProtectedRoutes(...routes: AppRoute[]) {
  const newRoutes: AppRoute[] = [];

  for (const route of routes) {
    const { element, isAuthorised, name, description } = route;

    const protectedRoute: AppRoute = {
      ...route,
      children: [],
      element: protectElement(element, {
        isAuthorised,
        name,
        description,
      }),
    };

    if (!route.rendersOwnChildren && route.children) {
      protectedRoute.children = generateProtectedRoutes(...route.children);
    }

    newRoutes.push(protectedRoute);
  }

  return newRoutes;
}

const ROUTES_PROTECTED = generateProtectedRoutes(...ENTRY_ROUTE);

export default ROUTES_PROTECTED;
