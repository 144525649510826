import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import React from "react";
import ErrorPage from "../ErrorPage";

export default function PageNotFound() {
  return (
    <ErrorPage
      icon={NotListedLocationIcon}
      heading="404"
      subText="Page Not Found!"
    />
  );
}
