import { Box, CircularProgress, Typography } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import React, { PropsWithChildren, Suspense } from "react";

export default function LoadingSuspense({
  subText,
  children,
}: PropsWithChildren<PlaceholderProps>) {
  return (
    <Suspense fallback={<Placeholder subText={subText} />}>{children}</Suspense>
  );
}

type PlaceholderProps = {
  subText?: string;
};

export function Placeholder({ subText }: PlaceholderProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        width: "100%",
        height: "100%",
      }}
    >
      <CircularProgress />
      <Typography variant="h1" mt={2}>
        Loading
      </Typography>
      <Typography variant="h4" component="h2" color={blueGrey[300]} mt={1}>
        {subText}
      </Typography>
    </Box>
  );
}
