import { SnackbarKey, useSnackbar } from "notistack";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeNotification,
  selectNotifications,
} from "../features/notification/notificationSlice";

let displayed: SnackbarKey[] = [];

export default function Notifier() {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(({ message, options, dismissed = false }) => {
      const key = options.key;
      if (dismissed) {
        // Dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // Do nothing if snackbar is already displayed
      if (displayed.includes(key)) {
        return;
      }

      // Display snackbar using notistack
      enqueueSnackbar(message, {
        ...options,
        onClose: (event, reason, key) => {
          if (options.onClose) {
            options.onClose(event, reason, key);
          }
        },
        onExited: (event, key) => {
          // Remove this snackbar from redux store
          dispatch(removeNotification(key));
          removeDisplayed(key);
        },
      });

      // Keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
}
