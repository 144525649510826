import DarkMode from "@kwaleeltd/common/lib/model/DarkMode";
import entries from "@kwaleeltd/common/lib/util/entries";
import DarkModeIcon from "@mui/icons-material/Brightness4";
import LightModeIcon from "@mui/icons-material/Brightness7";
import AutomaticIcon from "@mui/icons-material/BrightnessAuto";
import {
  IconButton,
  Menu,
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
  styled,
  SvgIcon,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import {
  selectDarkMode,
  setDarkMode,
} from "../../../../features/general/generalSlice";
import { useAppDispatch, useAppSelector } from "../../../../features/hooks";

interface MenuItemProps extends MuiMenuItemProps {
  isActive?: boolean;
}

const MenuItem = styled(MuiMenuItem, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<MenuItemProps>(({ isActive }) => ({
  fontWeight: isActive ? "bold" : undefined,
}));

export default function DarkModeButton() {
  const darkMode = useAppSelector(selectDarkMode);
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setMode = (key: keyof typeof DarkMode) => {
    dispatch(setDarkMode(key as keyof typeof DarkMode));

    handleClose();
  };

  let ModeIcon: typeof SvgIcon;
  switch (darkMode) {
    case "dark":
      ModeIcon = DarkModeIcon;
      break;
    case "light":
      ModeIcon = LightModeIcon;
      break;
    case "detect":
    default:
      ModeIcon = AutomaticIcon;
      break;
  }

  return (
    <>
      <IconButton
        color="inherit"
        component="span"
        aria-label="dark mode"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <ModeIcon />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {entries(DarkMode).map(([key, name]) => (
          <MenuItem
            key={key}
            onClick={() => setMode(key)}
            isActive={darkMode === key}
          >
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
