import fetchBaseQueryWrapper from "@kwaleeltd/common/lib/query/fetchBaseQueryWrapper";
import requestLimitQueryWrapper from "@kwaleeltd/common/lib/query/requestLimitQueryWrapper";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";
import { logout } from "../features/session/sessionSlice";
import { RootState } from "../features/store";
import { API_URL } from "./axiosConfig";

export const baseQueryWithLogout: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = async (args, steamApi, extraOptions) => {
  let result = await baseQuery(args, steamApi, extraOptions);

  if (result.error && result.error.status === 401) {
    steamApi.dispatch(logout());
  }

  return result;
};

export const baseQuery = requestLimitQueryWrapper(
  25,
  fetchBaseQueryWrapper({
    baseUrl: API_URL,
    prepareHeaders: (headers, { getState }) => {
      const {
        session: { email, token },
      } = getState() as RootState;

      const timestamp = Date.now().toString();

      if (token) {
        headers.set(
          "Authorization",
          `Bearer ${btoa(`${email}:${token}:${timestamp}`)}`
        );
      }

      headers.set("timestamp", `${timestamp}`);

      return headers;
    },
    prepareParameters: (headers, { getState }, method) => {
      const timestamp = headers.get("timestamp");

      return {
        timestamp,
      };
    },
  })
);
