import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { SnackbarProvider } from "notistack";
import React, { PropsWithChildren, useMemo } from "react";
import useDarkMode from "../common/useDarkMode";
import options from "./options";
import darkOptions from "./options/dark";

export default function Theme({ children }: PropsWithChildren<unknown>) {
  const isDarkMode = useDarkMode();

  const theme = useMemo(
    () => createTheme(deepmerge(options, isDarkMode ? darkOptions : {})),
    [isDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <CssBaseline />

        {children}
      </SnackbarProvider>
    </ThemeProvider>
  );
}
