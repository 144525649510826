import defaultSerializeQueryArgs from "@kwaleeltd/common/lib/query/defaultSerializeQueryArgs";
import ignoreSerializingQueryArgs from "@kwaleeltd/common/lib/query/ignoreSerializingQueryArgs";
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithLogout } from "../../../data/baseQuery";
import ZeusPaginationResponse, {
  ZeusPagination,
} from "../../../util/ZeusPagination";
import SteamApp from "./SteamApp";
import { SteamCategory } from "./SteamCategory";
import { SteamDeveloper } from "./SteamDeveloper";
import { KwalidaysEmployee } from "./SteamEmployee";
import {
  STEAM_APPS,
  STEAM_CATEGORIES,
  STEAM_DEVELOPERS,
  STEAM_EMPLOYEE_DETAILS,
  STEAM_GENRES,
  STEAM_PUBLISHERS,
} from "./steamEndpoint";
import { SteamGenre } from "./SteamGenre";
import { SteamPublisher } from "./SteamPublisher";

export type SteamAppParams = Partial<{
  app_types: string[];
  genre_ids: string[];
  category_ids: string[];
  developer_ids: string[];
  publisher_ids: string[];
  name: string;
  min_followers: number;
  max_followers: number;
}> &
  ZeusPagination;

export const steamApi = createApi({
  reducerPath: "steamApi",
  baseQuery: baseQueryWithLogout,
  serializeQueryArgs: ignoreSerializingQueryArgs(
    defaultSerializeQueryArgs,
    "timestamp"
  ),
  endpoints: (builder) => ({
    getSteamApps: builder.query<
      ZeusPaginationResponse<SteamApp>,
      SteamAppParams
    >({
      query: (params) => ({
        url: STEAM_APPS,
        params: {
          ...params,
          page: (params.page ?? 0) + 1,
        },
      }),
    }),
    getSteamGenres: builder.query<
      ZeusPaginationResponse<SteamGenre>,
      ZeusPagination
    >({
      query: (params) => ({
        url: STEAM_GENRES,
        params: {
          ...params,
          page: (params.page ?? 0) + 1,
        },
      }),
    }),
    getSteamCategories: builder.query<
      ZeusPaginationResponse<SteamCategory>,
      ZeusPagination
    >({
      query: (params) => ({
        url: STEAM_CATEGORIES,
        params: {
          ...params,
          page: (params.page ?? 0) + 1,
        },
      }),
    }),
    getSteamPublishers: builder.query<
      ZeusPaginationResponse<SteamPublisher>,
      ZeusPagination
    >({
      query: (params) => ({
        url: STEAM_PUBLISHERS,
        params: {
          ...params,
          page: (params.page ?? 0) + 1,
        },
      }),
    }),
    getSteamDevelopers: builder.query<
      ZeusPaginationResponse<SteamDeveloper>,
      ZeusPagination
    >({
      query: (params) => ({
        url: STEAM_DEVELOPERS,
        params: {
          ...params,
          page: (params.page ?? 0) + 1,
        },
      }),
    }),
    getKwalidays: builder.query<
      ZeusPaginationResponse<KwalidaysEmployee>,
      ZeusPagination
    >({
      query: (params) => ({
        url: STEAM_EMPLOYEE_DETAILS,
        params: {
          ...params,
          page: (params.page ?? 0) + 1,
        },
      }),
    }),
  }),
});

export const {
  useGetSteamAppsQuery,
  useGetSteamGenresQuery,
  useGetSteamCategoriesQuery,
  useGetSteamPublishersQuery,
  useGetSteamDevelopersQuery,
  useGetKwalidaysQuery,
} = steamApi;
