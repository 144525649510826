import { AxiosResponse } from "axios";
import forge from "node-forge";
import { axiosConfig } from "../../data/axiosConfig";
import isMobileAndTablet from "../../util/isMobileAndTablet";
import { LoginResponse } from "../../permissions/Permission";
import { AUTHENTICATE, LOGOUT } from "./sessionEndpoint";

const PUBLIC_KEY = forge.pki.publicKeyFromPem(process.env.REACT_APP_PUBLIC_KEY);

enum PlatformType {
  DESKTOP = "desktop",
  MOBILE = "mobile",
}

export function encryptPassword(
  password: string,
  options: Partial<{ timestamp: number; platform: PlatformType }> = {}
) {
  // md5 Password
  const md5 = forge.md.md5.create();
  md5.update(password);
  password = md5.digest().toHex();

  const {
    timestamp = Date.now(),
    platform = isMobileAndTablet() ? PlatformType.MOBILE : PlatformType.DESKTOP,
  } = options;

  // Add timestamp
  password = `f(${password}):${platform}:${timestamp}`;

  // Encrypt with RSA
  const encrypted = PUBLIC_KEY.encrypt(password, "RSA-OAEP", {
    md: forge.md.sha256.create(),
    mgf1: forge.mgf.mgf1.create(forge.md.sha1.create()),
  });

  // Base 64
  password = forge.util.encode64(encrypted);

  return password;
}

export function login(email: string, password: string) {
  const timestamp = Date.now();
  password = encryptPassword(password, { timestamp });

  return axiosConfig
    .post<
      {
        email: string;
        data: string;
        timestamp: number;
      },
      AxiosResponse<LoginResponse>
    >(
      AUTHENTICATE,
      {
        email,
        data: password,
        timestamp,
      },
      {
        headers: {
          timestamp: timestamp.toString(),
        },
      }
    )
    .then((response) => ({
      session_token: response.data.session_token,
      timestamp,
      access: response.data.access,
    }));
}

export function logout() {
  return axiosConfig.post<never>(LOGOUT).catch((ignored) => {});
}
