import axios, { AxiosRequestConfig } from "axios";

export const API_URL = process.env.REACT_APP_API_URL;

export const axiosConfig = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export function isValidURL(config: AxiosRequestConfig) {
  return (
    config.url &&
    (config.url.startsWith(API_URL) ||
      (config.baseURL === API_URL && config.url.startsWith("/")))
  );
}
