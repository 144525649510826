import Meta, { MetaProps } from "@kwaleeltd/common/lib/Meta/Meta";
import React, { ReactNode } from "react";
import { RouteProps } from "react-router-dom";

export type ComponentRouteProps = MetaProps &
  RouteProps & { element: ReactNode };

const ComponentRoute = ({
  name,
  description,
  appendAppName,
  element,
}: ComponentRouteProps) => {
  return (
    <>
      <Meta
        name={name!}
        description={description}
        appendAppName={appendAppName}
      />

      {element}
    </>
  );
};

export default ComponentRoute;
