import RouteEndpoint from "../../../routes/definitions/RouteEndpoint";

export const SteamEndpoint: RouteEndpoint = "/steam";

export const SteamCategoriesEndpoint: RouteEndpoint = `${SteamEndpoint}/categories`;
export const SteamCategoryEndpoint: RouteEndpoint = `${SteamEndpoint}/categories/:steamCategoryId`;

export const SteamGenresEndpoint: RouteEndpoint = `${SteamEndpoint}/genres`;
export const SteamGenreEndpoint: RouteEndpoint = `${SteamEndpoint}/genres/:steamGenreId`;

export const SteamDevelopersEndpoint: RouteEndpoint = `${SteamEndpoint}/developers`;
export const SteamDeveloperEndpoint: RouteEndpoint = `${SteamEndpoint}/developers/:steamDeveloperId`;

export const SteamPublishersEndpoint: RouteEndpoint = `${SteamEndpoint}/publishers`;
export const SteamPublisherEndpoint: RouteEndpoint = `${SteamEndpoint}/publishers/:steamPublisherId`;

export const SteamAppsEndpoint: RouteEndpoint = `${SteamEndpoint}/apps`;
export const SteamAppEndpoint: RouteEndpoint = `${SteamEndpoint}/app/:steamAppId`;

export default SteamEndpoint;
