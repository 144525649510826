import React from "react";
import { useRoutes } from "react-router-dom";
import AppRoute from "./definitions/route";

export default function RouteRenderer({
  routes = [],
}: {
  routes: AppRoute[] | undefined;
}) {
  return useRoutes(routes);
}
