import { generateProtectedRoutes } from "../../routes/definitions";
import PageNotFoundRoute from "../Error/PageNotFound/PageNotFoundRoute";
import KwalidaysRoute from "./Kwalidays/KwalidaysRoute";
import HomeRoute from "./Home/HomeRoute";
import { SteamRoutes } from "./Steam/SteamRoute";

const LOGGED_IN_ROUTE_CHILDREN = [
  HomeRoute,
  KwalidaysRoute,
  ...SteamRoutes,
  PageNotFoundRoute,
];

export default LOGGED_IN_ROUTE_CHILDREN;

export const LOGGED_IN_ROUTE_PROTECTED = generateProtectedRoutes(
  ...LOGGED_IN_ROUTE_CHILDREN
);
